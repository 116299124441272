<template>
  <section v-if="info != null" :key="loa_level" v-bind:class="{ '': notSending }" v-show="info.authentication_method != 'donotsend'">
    <div class="d-flex" :key="options.length">
      <div class="flex-grow-1">     
        <div  class="form-control-plaintext">
          <i class="fal fa-trash-alt clickable mr-2" @click="removeBtn"></i>{{info.email}}
        </div>
      </div>
      <div>     
        <b-form inline class="mb-2 w-350" v-if="info.authentication_method == 'conversation'" >
          <b-form-input class="w-350" plaintext :value="$t('CONVERSATION_CONFIGURED')"></b-form-input>
        </b-form>
        <b-form inline class="mb-2" v-if="info.authentication_method != 'conversation'">     
          <b-form-select name="not_registered_select_language" v-model="info.language" class="choose-lang">
            <b-form-select-option
              v-for="(value, key) in sefos_locales"
              :key="key"
              :value="key"
            >
              {{ $t("LANGUAGE." + key) }}
            </b-form-select-option>
          </b-form-select>
          <b-form-select
           class="w-250 no-mobile-padding-left"
            v-model="info.authentication_method"
            :options="options"
            :state="validated"
          ></b-form-select>
          <div class="mobile-block">
            <EmailOTP
              @valid="setValidated"
              @changed="changedData"
              v-model="info.authentication_identifier"
              v-if="info.authentication_method == 'email-otp'"
            ></EmailOTP>
            <Phone
              @valid="setValidated"
              @changed="changedData"
              v-model="info.authentication_identifier"
              v-if="info.authentication_method == 'sms'"
            ></Phone>
            <Pnr
              @valid="setValidated"
              @changed="changedData"
              v-model="info.authentication_identifier"
              v-if="info.authentication_method == 'se-eid'"
              ></Pnr>
          </div>
        </b-form>        
      </div>
    </div>
  </section>
</template>
<script>
import Pnr from "@/components/Input/Pnr.vue";
import Phone from "@/components/Input/Phone.vue";
import EmailOTP from "@/components/Input/EmailOTP.vue";
export default {
  components: { EmailOTP, Phone, Pnr },
  props: ["showSefos","item", "index", "methods", "loa_level"],
  data() {
    return {
      validated: null,
      info: this.item,
      languages: [],
      options: []
    };
  },
  methods: {
    removeBtn() {
      let self = this;
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.info.authentication_method = "donotsend";
        }
      })
      .catch(function(){
      }); 
    },
    init() {
      if(this.info.authentication_method != 'conversation')
      {
        if(this.showSefos)
        {
          this.options.push({
            value: "sefos-account",
            text: this.$t("AUTH_METHODS.sefos-account"),
          });
        }
        for (let ix = 0; ix < this.methods.length; ix++) {
          let method = this.methods[ix];
          if (method.loa_level >= this.loa_level) {
            if(method.name == "link")
            {
              if(this.isPhone){
                this.info.authentication_method = "link-sms";
                this.options.push({
                  value: "link-sms",
                  text: this.$t("AUTH_METHODS.sms"),
                });
              }else{
                this.options.push({
                value: "link",
                text: this.$t("AUTH_METHODS.link"),
              });
              }
            }else{
              if(method.name == "sms")
              {
                if(this.isPhone){
                  if(this.loa_level == 2)
                  {
                    this.info.authentication_method = "email-otp";
                  }
                  this.options.push({
                    value: "email-otp",
                    text: this.$t("AUTH_METHODS.email-otp"),
                  });
                }else{
                  this.options.push({
                    value: "sms",
                    text: this.$t("AUTH_METHODS.sms"),
                  });
                }
              }else{            
                this.options.push({
                  value: method.name,
                  text: this.$t("AUTH_METHODS." + method.name),
                });
              }
            }
          }
          if (this.loa_level == 2) {
            if(this.isPhone)
            {
              this.info.authentication_method = "email-otp";
            }else{
              this.info.authentication_method = "sms";
            }        
          }
          if (this.loa_level == 3) {
            this.info.authentication_method = "se-eid";
          }  
          if(this.showSefos)
          {
              this.info.authentication_method = "sefos-account"; 
          }
        }
      }
    },
    setValidated(value) {
      this.validated = value;
      this.$emit("setValidated", { index: this.index, value: value });
    },
    setMethod(key) {
      this.info.authentication_method = key;
    },
    changedData(result) {
      this.info.authentication_identifier = result.value;
    },
  },
  watch: {
    "info.authentication_method": function(newVal) {
      this.info.authentication_identifier = "";
      if (newVal == "donotsend") {
        this.setValidated(true);
      }
      if (newVal == "link") {
        this.setValidated(true);
      }
      if (newVal == "link-sms") {
        this.setValidated(true);
      }
      if( (newVal == "sms")
      ||  (newVal == "email-otp") 
      ||  (newVal == "se-eid") 
      ||  (newVal == "bankid-se") 
      ||  (newVal == "freja") 
      )  {
        this.setValidated(null);
      }
      if (newVal == "siths") {
        this.setValidated(true);
      }
      if (newVal == "siths-card") {
        this.setValidated(true);
      }
      if (newVal == "sefos-certificate") {
        this.setValidated(true);
      }
      if (newVal == "sefos-account") {
        this.setValidated(true);
      }
    },
  },
  computed: {
    isEmail(){
      return this.validateEmail(this.info.email);
    },
    isPhone(){
      return this.validatePhone(this.info.email);
    },
    notSending() {
      return this.info.authentication_method == "donotsend";
    },
  },
  mounted() {
    if (this.info.authentication_method == "donotsend") {
      this.setValidated(true);
    }
    if (this.info.authentication_method == "link") {
      this.setValidated(true);
    }
    if (this.info.authentication_method == "link-sms") {
      this.setValidated(true);
    }
    if (this.info.authentication_method == "sms") {
      this.setValidated(null);
    }
    if (this.info.authentication_method == "email-otp") {
      this.setValidated(null);
    }
    if (
      this.info.authentication_method == "freja" ||
      this.info.authentication_method == "bankid-se" ||
      this.info.authentication_method == "se-eid"
    ) {
      this.setValidated(null);
    }
    if (this.info.authentication_method == "siths") {
      this.setValidated(true);
    }
    if (this.info.authentication_method == "sefos-certificate") {
      this.setValidated(true);
    }
    this.init();
  },
};
</script>
<style></style>